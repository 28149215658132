import React, { useState } from 'react'
import PropTypes from 'prop-types'
import kebabCase from 'lodash/kebabCase'
import { graphql } from 'gatsby'
import Base from '../components/Base'
import Meta from '../components/Meta'
import { FilterField, ListLink } from '../templates/styles'

const TagsPage = props => {
  const { group } = props.data.allMdx
  const sortedTags = group.sort((a, b) => {
    // These have to be lower cased or the capitalized values will come first.
    const aValue = a.fieldValue.toLowerCase()
    const bValue = b.fieldValue.toLowerCase()

    if (aValue < bValue) return -1
    if (aValue > bValue) return 1

    return 0
  })
  const [searchResults, setSearchResults] = useState(sortedTags || [])

  const handleChange = event => {
    const value = event.target.value.toLowerCase()

    if (value) {
      const filteredTags = sortedTags.filter(tag =>
        tag.fieldValue?.toLowerCase().includes(value)
      )

      setSearchResults(
        filteredTags.length > 0 || value ? filteredTags : sortedTags
      )
    } else {
      setSearchResults(sortedTags)
    }
  }

  return (
    <Base pathName={props.location.pathname}>
      <Meta location={props.location} pageTitle="Tags page" />
      <h2>Tags</h2>
      <p>It’s just, like, a really long list.</p>
      <FilterField
        aria-controls="tag-list"
        onChange={handleChange}
        placeholder="Filter"
        aria-label="filter"
        type="search"
      />
      <ul aria-live="polite" id="tag-list" role="region">
        {searchResults.length < 1 ? (
          <li>No results</li>
        ) : (
          searchResults.map(tag => (
            <li key={tag.fieldValue}>
              <ListLink to={`/tag/${kebabCase(tag.fieldValue?.toLowerCase())}`}>
                {tag.fieldValue} ({tag.totalCount})
              </ListLink>
            </li>
          ))
        )}
      </ul>
    </Base>
  )
}

TagsPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired
        }).isRequired
      )
    })
  })
}

export default TagsPage

export const pageQuery = graphql`
  query {
    allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
